export default {
	tabbar: {
	    "首页": "홈",
	    "市场": "시장",
	    "任务": "태스크",
	    "团队": "팀",
	    "我的": "내 정보"
	},
	navbar: {
	    "充值": "입금",
	    "提现": "출금",
	    "帮助": "가이드",
	    "分享": "초대코드",
	    "活动": "이벤트"
	},
	payment: {
	    "付款方式详情": "결제 수단 세부정보",
	    "银行卡": "지갑",
	    "钱包地址": "지갑 주소",
	    "交易密码": "거래 비밀번호",
	    "保存": "저장"
	},
	market: {
	    "市场": "시장",
	    "名称": "이름",
	    "价格": "가격"
	},
	task: {
	    "任务": "직무",
	    "语言切换": "언어 전환",
	    "我的余额": "내 잔액",
	    "增加订单数量": "주문 수량 늘리기",
	    "今日剩余订单": "오늘 남은 주문",
	    "频率": "빈도",
	    "手动交易": "수동 거래",
	    "预期收益": "예상 수입",
	    "需要启动存款": "입금을 시작해야 합니다",
	    "任务说明": "과업 설명",
	    "每天可以完成10个任务": "하루에 10개의 작업을 완료할 수 있습니다.",
	    "联系VIP助手增加任务数量": "작업 수를 늘리려면 VIP 도우미에게 문의하세요.",
	    "每个用户的任务总数为300次": "사용자당 총 작업 수는 300개입니다.",
	    "购买机器人无需人工操作即可自动交易": "AI 구매 시 수동 조작 없이 자동 거래",
	    "我知道了": "알겠어요!"
	},
	"团队": "팀",
	"规则": "Tip",
	"会员级别": "나의 레벨",
	"经验": "포인트",
	"代理信息": "에이전트 정보",
	"更多": "더 보기",
	"团队奖励": "팀 리워드",
	"昨天": "어제",
	"本星期": "이번 주",
	"全部": "모두",
	"团队收入记录": "팀 리워드 기록",
	"机器人回扣": "상품 리워드",
	"会员体验": "회원 경험",
	"个人收入记录": "개인 소득 기록",
	"机器人采购": "상품 조달",
	"机器人启动押金": "상품 시작 보증금",
	"机器人礼物": "상품 선물",
	"机器人订单": "상품 주문",
	"机器人": "상품",
	"一次性总和": "일회성 합계",
	"总计": "계",
	"我的机器人": "나의 위탁 상품 리스트",
	"被激活": "시작하기",
	"工作中": "위탁 중",
	"暂停": "중지",
	"结束": "종료",
	"刷新": "새로 고침",
	"来源": "구분",
	"价格": "위탁 수량",
	"启动押金": "시작 가격",
	"剩余时间": "남은 시간",
	"激活时间": "시작 시간",
	"号": "번호.",
	"我的资产": "내 자산",
	"退出": "로그아웃",
	"邀请码": "초대 코드",
	"我的钱包余额": "나의 지갑 잔고",
	"我的机器人仓库": "나의 상품 리스트",
	"代理信息": "한 눈에 보기",
	"去提款": "출금",
	"昨天代理返利": "어제 에이전트 리워드",
	"累计代理返利": "누적 에이전트 리워드",
	"今天机器人收益": "오늘 수익",
	"累计机器人收益": "누적 소득",
	"累计机器人回扣": "누적 리워드",
	"累计总回报": "누적 총 수익",
	"自动订单机器人数量": "자동주문 상품 수량",
	"我的付款方式": "내 결제 수단",
	"个人收入记录": "개인 소득 기록",
	"团队收入记录": "팀 수익 기록",
	"活动奖金收益": "이벤트 보너스 수익",
	"存款赢豪礼": "선물을 획득하다",
	"邀请赢豪礼": "호화선록 초청",
	"充值记录": "입금 기록",
	"存取款记录": "입출금 기록",
	"申请奖金": "보너스 요청 금액 ",
	"视频URL": "광고물 게시 링크",
	"活动时间": "신청일",
	"我的团队成员": "내 팀원 리스트",
	"活动中心": "이벤트 센터",
	"修改登录密码": "로그인 비밀번호 관리",
	"交易密码管理": "거래 비밀번호 관리",
	"机器人": "상품",
	"机器人性能": "상품 정보",
	"机器人价格": "상품 가격",
	"登录密码错误": "로그인 암호 오류",
	"有效期": "위탁 기간",
	"修改成功": "변경 완료",
	"天": "일",
	"去购买": "구매",
	"买个机器人": "상품 구매",
	"预计日收益": "예상 수익(일)",
	"启动保证金": "입금 시작",
	"请输入购买数量": "구매 수량을 입력해주세요",
	"个机器人": " 개 / 상품",
	"机器人购买后需要激活才能工作": "구매 후 작동을 하려면 상품을 시작해야 합니다.",
	"机器人激活需要从余额中扣除相应的启动押金": "상품 시작은 잔액에서 해당 시작 보증금을 공제해야 합니다.",
	"机器人暂停机器人停止时将返还启动押金": "상품 기간이 종료되면 시작 보증금이 반환됩니다.",
	"不激活的机器人可以赠送": "위탁 시작 전 상품을 취소할 수 있습니다.",
	"购买": "구매",
	"点击去了解我们": "T Link에 대해 알아보려면 클릭하십시오",
	"购买机器人": "상품 구매",
	"快速入口": "빠른 입장",
	"日/个人受益": "일일/개인 혜택",
	"购买教程": "상품 구매",
	"订单数量": "주문 수량",
	"我们的合作伙伴": "파트너",
	"提款": "출금",
	"提款金额": "출금 수량 ",
	"你还没有添加银行卡": "지갑 주소를 아직 등록하지 않았습니다.",
	"提现说明": "출금 안내",
	"确认提款": "출금 확인 ",
	"钱包余额": "지갑 잔액",
	"输入数量": "USDT 금액 입력",
	"数量": "수량",
	"登录": "로그인",
	"输入账号": "지갑주소 입력",
	"输入密码": "비밀번호 입력",
	"还没有账号点击注册": "회원가입",
	"忘记密码": "로그인 비밀번호 관리",
	"重新登录": "다시 로그인",
	"密码找回成功": "비밀번호 변경이 완료되었습니다.",
	"找回成功请重新登录": "성공적으로 변경되었습니다. 다시 로그인하십시오.",
	"确认": "변경하기",
	"发送验证码": "인증 코드 전송",
	"再次输入密码": "비밀번호 확인",
	"输入验证码": "인증 코드 입력",
	"输入手机号": "휴대폰 번호 입력",
	"注册": "등록",
	"每分钟只能发送一次": "분당 한 번만 발송 가능",
	"输入用户名": "사용자 이름을 입력",
	"请输入邀请码": "초대 코드 입력",
	"返回": "뒤로가기",
	"密码找回失败": "비밀번호 변경 실패",
	"输入的信息有误": "비밀번호를       다시 확인하세요.",
	"获取验证码": "코드 받기",
	"手机号码不能为空": "휴대폰 번호는 입력해주세요.",
	"账号不存在": "등록된 계정이 없습니다.",
	"发送成功": "전송 완료",
	"今日收益": "오늘의 수익",
	"账户": "계정",
	"日收益": "수익(일)",
	"数量不能为空": "수량을           입력하세요.",
	"加载中": "Lodding...",
	"唯一码": "거래 코드",
	"未激活": "구매",
	"激活": "시작하기",
	"购买时间": "구매 시간",
	"钱包余额不足，激活该机器人失败": "잔액 부족, 상품 위탁 실패",
	"暂无数据": "데이터 없음",
	"激活时间": "시작 시간",
	"运行时长": "실행 시간",
	"签约购买": "계약 구매",
	"系统赠送": "시스템 선물",
	"状态": "상태",
	"正常": "정상",
	"我的机器人": "나의 위탁 상품",
	"一级会员": "레벨 1",
	"二级会员": "레벨 2",
	"三级会员": "레벨 3",
	"人": "명",
	"充值客服": "고객 서비스 충전",
	"充值": "입금",
	"暂无备注": "-",
	"请输入完整信息": "정보를 정확히 입력하세요.",
	"提现": "출금",
	"在区块浏览器上查看": "청크 탐색기에서 보기",
	"奖金个人收益": "보너스",
	"联系客服": "고객 서비스에 문의",
	"设置交易密码": "거래 비밀번호 관리",
	"登录密码": "로그인 비밀번호 입력",
	"请输入交易密码": "거래 비밀번호(6자리 이상)",
	"再次请输入交易密码": "거래 비밀번호 확인",
	"确认": "확인",
	"手机号码不能为空": "휴대폰 번호를 입력해주세요.",
	"两次密码不一致": "비밀번호를       확인하세요.",
	"两次密码输入不一致": "비밀번호를       확인하세요",
	"请输入验证码": "인증 코드를 입력 해주세요",
	"操作成功": "작업이 완료되었습니다.",
	"用户名或密码不正确,登录失败": "입력된 정보를   확인하세요.",
	"登录成功": "로그인 성공",
	"充值说明": "입금 안내",
	"请先设置支付密码": "결제 비밀번호를 설정하세요",
	"复制成功": "복사 완료",
	"冻结机器人做单本金": "상품 주문의 주체를 동결",
	"待审核": "검토 중",
	"成功": "처리 완료",
	"失败": "실패",
	"审核中": "검토 중",
	"在线充值": "온라인 충전",
	"描叙": "네트워크",
	"银行卡提现": "지갑 주소 입출금",
	"USDT提现": "USDT 출금",
	"三级代理": "3차 에이전트",
	"一级代理": "1차 에이전트",
	"二级代理": "2차 에이전트",
	"做单": "주문",
	"团队返佣": "팀 리워드",
	"购买机器人返佣": "상품 리워드",
	"本金返还": "원금 반환",
	"时间": "시간",
	"机器人做单返还": "상품 주문을 하고 반환",
	"涨幅": "증가",
	"付款方式": "나의 지갑 관리",
	"我的留言": "내 메시지",
	"银行卡": "지갑",
	"修改": "등록 / 변경",
	"确保钱包地址正确，钱包以防地址输入错误而收不到钱用户造成的损失": "지갑 주소가 정확한지 확인하세요. 잘못된 주소 등록으로 인한 사용자로 인한 손실을 방지합니다.",
	"连接借记卡": "지갑 등록",
	"银行名称": "지갑 이름",
	"姓名姓氏": "이름",
	"IBAN号码": "지갑 주소",
	"* 重要提示:借记卡信息必须真实有效才能取款。": "* 중요: 출금하기 전에 지갑주소가 유효해야 합니다..",
	"帮助中心": "가이드",
	"会员须知": "회원 참고 사항",
	"24小时内使用USDT实时取款（推荐）": "1. 24시간 이내 usdt로 실시간 출금(권장)",
	"银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账": " 2. 지갑 주소 출금 * 출금시간은 10:00-18:00 * 출금 신청 후 2시간 이내 지갑주소로 입금 * 무출금 또는 지갑간 출금 신청은 익일 10:00-18:00에 접수",
	"我知道了": "알겠어요!",
	"链接点击": "링크 클릭",
	"建立自己的团队邀请": "나만의 팀 초대장 만들기",
	"加入": "가입",
	"你出售的机器人对方已接受,出售所得已打款到您账户,请查收。": "귀하가 판매한 상품에 대하여 상대방이 수락했습니다. 판매 수익이 귀하의 계정으로 지급되었습니다. 확인해주십시오.",
	"你出售的机器人对方已拒绝接受": "귀하가 판매한 상품이 상대방에 의해 거부되었습니다.",
	"向你赠送一台机器人": "상품 선물하기.",
	"向你出售一台机器人": "상품 판매하기.",
	"出售价格不能超过机器人价格": "판매 가격은 상품 가격을 초과할 수 없습니다.",
	"银行卡已被绑定": "지갑 주소가 등록되었습니다.",
	"USDT_TRC20提现": "USDT-TRC20 출금",
	"USDT_ERC20提现": "USDT-ERC20 출금",
	"银行卡提现": "지갑주소 출금",
	"邀请赠送": "초대 보너스",
	"机器人返佣": "오늘 팀 리워드",
	"升级": "업그레이드",
	"充值主网": "메인 네트워크 입금",
	"您被限制出售机器人,请联系客服了解详情": "상품 판매가 제한됩니다. 자세한 내용은 고객 서비스에 문의하십시오",
	"交易编号": "거래 Hash",
	"消息详情": "메시지 세부정보",
	"个人收益累计": "누적 개인 수익",
	"今日代理收益": "오늘 개인 수익",
	"代理累计收益": "누적 팀 리워드",
	"验证码错误或者账户已注册": "인증코드 오류",
	"截图保存推荐给朋友": "스크린샷을 저장하여 공유",
	"复制": "복사",
	"请输入正确的充值金额": "입금할 수량을 입력하세요.",
	"推荐": "추천하기",
	"充值金额": "입금 수량",
	"请上传付款截图": "스크린샷을      업로드하세요.",
	"充值ID提示": "Hash ID 입력",
	"请确保钱包地址无误，如因钱包地址填写错误导致无法收款而造成的损失，将有用户自己承担。": "스크린샷 내용이 일치하는지 확인하십시오.  내용이 일치하지 않을 경우. 발생되는 손실은 사용자 부담.",
	"上传凭证": "입금 내역 업로드",
	"不能为空": "내용을 입력해주세요.",
	"购买成功": "입금 성공",
	"购买失败": "입금 실패",
	"绑定钱包地址": "지갑 주소 등록",
	"备注": "비고",
	"请输入你的名字": "이름을 입력하세요",
	"请输入银行卡号": "지갑 주소를 입력하세요.",
	"银行卡号": "지갑 주소",
	"银行": "지갑",
	"激活成功": "시작 성공",
	"添加银行卡": "지갑 주소 추가",
	"请选择银行": "지갑을 선택해 주세요",
	"请输入钱包地址": "지갑주소를 입력해주세요",
	"钱包地址": "지갑 주소",
	"取款须知": "출금 지침",
	"须知1": "1. USDT로 출금(권장)",
	"须知2": "2. 지갑 주소 확인",
	"须知3": "출금 * 출금 시간은 출금 시점으로부터 약 72시간 이내 처리 완료",
	"须知4": "*출금시간 또는 지갑 주소간 출금 신청은 익일 10:00~18:00에 접수됩니다.",
	"已绑定": "주의",
	"去设置": "설정",
	"汇率": "환율",
	"请输入正确的提现金额": "출금 수량을      입력하세요",
	"提款记录": "출금 기록",
	"绑定的钱包地址必须跟付款钱包一致，否则不会到账。": "Tip1 : 등록된 지갑 주소는 본인의 실제 지갑과 일치해야 합니다. 그렇지 않으면 전송되지 않습니다.",
	"充值成功后预计在一分钟左右到账，请检查钱包约。": "Tip2 : 입금 완료 후 검토를 거친 뒤 반영이 완료됩니다.",
	"请先绑定钱包地址": "지갑 주소를       등록하세요",
	"输入邮箱": "이메일",
	"指定用户不存在": "사용자가 존재하지 않습니다",
	"今日涨幅": "등락률",
	"快捷充币": "입금",
	"快速买USDT": "",
	"在线支付": "온라인 결제",
	"自动到账": "자동 영수증",
	"线下支付": "오프라인 결제",
	"联系客服获取验证码": "인증 코드는 고객 서비스에 문의",
	"获取": "얻다",
	"重新登录": "다시 로그인",
	"退出成功": "종료 성공",
	"不能低于最小充值": "최소 입금량보다 낮을 수 없습니다.",
	"不能低于最小提现金额": "최소 출금수량 이상 입력",
	"最小提现": "최소 출금",
	"官方充币": "입금",
	"银行卡充值": "지갑 주소 입금",
	"等待审核": "검토 보류",
	"可提现金额不足": "잔고 부족 ",
	"未经授权": "인증 실패",
	"交易密码不正确": "잘못된 거래 비밀번호",
	"提现次数不足": "불충분한 출금 시간",
	"需要更新的银行卡不存在": "업데이트할 지갑 주소가 존재하지 않습니다.",
	"银行卡提现": "지갑 주소 출금",
	"激活金额": "시작 금액",
	"赠送/出售": "선물/판매",
	"赠送": "선물하기",
	"出售": "판매",
	"购买需要直推": "구매 요구 조건",
	"人": "명",
	"推荐满": "구매 조건",
	"推荐满1人可购买": "추천 1인 구매 가능",
	"推荐满2人可购买": "추천 2인 구매 가능",
	"推荐满3人可购买": "추천 3인 구매 가능",
	"推荐满5人可购买": "추천 5인 구매 가능",
	"推荐满10人可购买": "추천 10인 구매 가능",
	"推荐满15人可购买": "추천 15인 구매 가능",
	"推荐满20人可购买": "추천 20인 구매 가능",
	"推荐满30人可购买": "추천 30인 구매 가능",
	"推荐满50人可购买": "추천 50인 구매 가능",
	"推荐满100人可购买": "추천 100인 구매 가능",
	"修改钱包": "등록된 지갑을 변경해야 하는 경우. 고객센터에 문의하세요",
	"人可购买": "구매 가능",
	"账户usdt不足": "USDT 잔액 부족",
	"请输入提现金额": "출금 수량을 입력하세요",
	"恭喜新用户": "가입완료 ",
	kefu1: "유튜브 동영상 튜토리얼",
	"登录密码": "로그인 비밀번호 입력",
	"再次请输入交易密码": "거래 비밀번호 확인",
	"输入手机号码": "휴대폰 번호",
	"输入手机号码": "휴대폰 번호 입력",
	"输入密码": "비밀번호 입력(6자리 이상)",
	"请输入密码": "비밀번호 입력",
	"手机号格式错误": "휴대폰 번호 오류",
	"密码错误次数过多,请等待x秒后再试": "잦은 비밀번호 입력 오류로 600초 후에 다시 시도하십시오.",
	"此账号已冻结,请联系客服": "이 계정은 정지되었습니다. 고객 센터에 문의하십시오",
	"登录失败": "로그인 실패",
	"请勿重复操作": "천천히 다시 시도해주세요",
	"邀请码不正确": "잘못된 초대 코드",
	"此账号已冻结": "이 계정은 정지되었습니다",
	"请输入手机号": "휴대폰 번호 혹은 메일을 입력하세요",
	"验证码错误": "인증 코드 오류",
	"手机号存已存在": "등록된 휴대폰 번호가 이미 존재합니다.",
	"注册账号已存在": "등록된 계정이 이미 존재합니다",
	"请确定新密码": "비밀번호를     입력하세요",
	"请再次输入登录密码": "비밀번호를     확인하세요",
	"密码长度不能少于6位": "비밀번호 길이는 6자리 이상이어야 합니다.",
	"输入邮箱": "이메일 입력",
	"注册邮箱已存在": "등록 사서함이 이미 있습니다.",
	"请输入用户ID": "사용자 ID를 입력하세요.",
	"输入对方账户名称，转入财产请仔细审核信息，如操作失误，无法通过系统或平台客服找回。": "상대방의 ID를 입력하고 재산을 양도할 경우 정보를 잘 살펴보시기 바랍니다. 운영상의 오류가 있는 경우 시스템 또는 플랫폼 고객센터를 통해 조회할 수 없습니다..",
	"请输入出售金额": "금액을 입력해주세요",
	"出售中": "구매 요청이 전달되었으며 위탁을 검토 중입니다.",
	"收到一台系统赠送的机器人": "시스템에서 상품을 받았습니다.",
	"会员出售": "회원 판매",
	"出售待确认中,请勿重复操作": "확인 검토 중인 판매, 작업을 반복하지 마십시오.",
	"激活成功": "시작",
	"操作成功": "완료",
	"输入邮箱": "이메일",
	"请输入登录密码": "비밀번호를      입력하세요",
	"未满足条件": "필요 조건 미충족",
	"活动已过期": "활동 만료됨",
	"邀请好友注册，领取佣金": "공유된 코드로 신규가입시 보상이 지급됩니다.",
	"领取": "받기",
	"团队规则": "팀 지침",
	"邮箱已存在": "이메일이 이미 존재합니다",
	"获取邮箱验证码": "코드 받기",
	"请输入邮箱": "이메일 주소 입력",
	"手机号码": "휴대폰",
	"手机登录": "휴대폰 로그인",
	"邮箱不能为空": "이메일을 입력해주세요.",
	"出售/赠送": "판매 / 선물",
	"已接受": "수락됨",
	"出售/赠送人": "판매자 / 제공자",
	"向你赠送一台机器人，待确认": "확인을 위해 상품이 제공됩니다.",
	"国码": "국가 코드",
	"取消": "취소",
	"银行卡号位数错误": "잘못된 지갑 주소",
	"官网": "공식 웹 사이트",
	"注册成功": "로그인 성공",
	"设置成功": "설정 완료",
	"手机号码已存在": "등록된 휴대폰 번호가 이미 존재합니다.",
	"修改成功": "완료",
	"立即登录": "이미 계정이 있습니다. 로그인하려면 클릭하세요.",
	"客服": "잡담",
	"邮箱": "이메일",
	"体验机器人不能赠送": "체험 상품은 양도할 수 없습니다.",
	"接受": "수용",
	"拒绝": "거절",
	"邮箱注册复制": "이메일 초대링크 복사",
	"手机注册复制": "휴대폰 번호 초대링크 복사",
	"邮箱邀请链接": "QR코드",
	"手机号邀请链接": "QR코드",
	Bono: "광고 보너스",
	"个人存款奖金活动": "개인 입금 보너스 캠페인",
	Lospuntos: "포인트",
	"Bono de YOUTUBE": "광고 보너스",
	"Publicar video bono": "신청 금액 범위",
	"Ingrese el monto de la solicitud": "신청 금액 입력",
	"Enlace de tu video": "URL",
	"Ingrese el enlace del video post-publicado": "링크를 입력하십시오.",
	"Notas importantes sobre los bonos": "중요 참고사항",
	bonosTip1: "광고물을 제작하여 개인 Blog, YouTube 채널, SNS에 업로드하여 요청시 10$-200$을 지급합니다",
	bonosTip2: "게시된 광고물을 검토 후 품질에 따라 계정으로 보너스를 지급합니다",
	bonosTip3: "같은 매체의 동일한 광고는 한 번만 보너스를 신청할 수 있으며, SNS의 경우 매 주 새로운 게시물을 게시할 수 있습니다",
	bonosTip4: "악용할 경우 계정 사용에 불이익이 생길 수 있습니다",
	"Aplicar para un premio": "신청하기",
	"Comisión de referidos LV1": "추천  커미션",
	"请输入投入金额": "투자 금액을 입력하십시오",
	"请输入范围内的金额": "해당 범위 내 금액을 입력하십시오.",
	"投入金额范围": "금액 범위",
	"投资限额": "투자 한도 범위",
	"用户名或密码不正确登录失败": "입력된 정보를    확인하세요",
	"实际奖励":"지급 결정",
	"倒计时": "잔여 시간",
	"格式错误":"형식 오류"
}
