<template>
    <div class="page">
        </br>
        <div class="header">
            <div @click="getBack" style="padding-top: 6px;padding-left: 10px;">
                <img class="img" src="../../assets/img/arrow.png" style="width: 25px;height: 25px;" alt=""/>
            </div>
            <div class="logg" style="color: #000;font-weight: bold;">{{ $t('忘记密码') }}</div>
            <div></div>

        </div>
        <div class=" mt24 ml32 mr32" style="height: 0; opacity: 2; border: 0.01px solid #4CAF50;"></div>
        <div class="content">
            <van-form>
                <div class="phone">

                    <van-field
                            class="input"
                            v-model="username"
                            :placeholder="$t('输入邮箱')+','+ $t('手机号码')"
                    />
                </div>
                <!--    <div class="captcha">
                        <van-field v-model="captcha" :placeholder="$t('输入验证码')"/>
                        <van-button class="textBtn" plain type="info" @click="getcode()">
                            <span v-show="show">{{ tishi }}</span>
                            <span v-show="!show">{{ count }} s</span>
                        </van-button>
                    </div> -->


                <van-field class="input" v-model="captcha" :placeholder="$t('输入验证码')">
                    <template #button>
                        <div v-if="!codeDisabled">
                            <van-button style="height: 32px; padding: 0 8px; font-size: 12px; vertical-align: top;" size="small" type="info"
                                        @click="getcode()"
                                        :disabled="codeDisabled">
                                {{ $t('获取验证码') }}
                            </van-button>
                        </div>
                        <div style="color: #FFFFFF" v-else>
                            <van-button style="height: 32px; padding: 0 8px; font-size: 12px; vertical-align: top;" size="small" type="info" disabled>
                                {{ codeTime }}
                            </van-button>
                        </div>

                    </template>
                </van-field>


                <van-field
                        class="input"
                        v-model="password"
                        type="password"
                        :placeholder="$t('输入密码')"
                />
                <van-field
                        class="input"
                        v-model="password2"
                        type="password"
                        :placeholder="$t('再次输入密码')"
                />
            </van-form>
        </div>
        <div class="footer">
            <van-tabbar v-model="active" route>
                <van-tabbar-item
                        v-for="item in tabbar"
                        :key="item.title"
                        :to="item.url"
                >
                    <span>{{ item.title }}</span>
                    <template #icon="props">
                        <img :src="props.active ? item.iconA : item.icon" alt=""/>
                    </template>
                </van-tabbar-item>
            </van-tabbar>
        </div>
        <ROBOT/>
        <div style="margin: 16px; padding: 32px 60px 0">
            <van-button class="btn" round block type="info" @click="subpasswd">{{
                    $t('确认')
                }}
            </van-button>
        </div>
    </div>

</template>

<script>
import {NavBar, Button, Field, Toast} from "vant";
import ROBOT from '@/components/robot'
import {apipaypassword, apiUser_mas2, apipaysms, apiRegss, apiForget, apiRegssByMail, apiSmsCode} from "@/http/api/";

export default {
    name: 'welcome',
    components: {

        ROBOT

    },
    data() {
        return {
            value: '+90',
            option1: [
                {text: "Turkey +90", value: "+90"},

            ],
            username: '',
            password: '',
            password2: '',
            captcha: '',
            count: 60,
            show: true,
            timer: null,
            tishi: this.$t("获取验证码"),
            key: '',
            codeDisabled: false,
            codeTime: 60,
            timer1: null,
            active:'',
        }
    },


    computed: {
        tabbar() {
            return [
                {
                    title: this.$i18n.t('tabbar.首页'),
                    icon: require('@/assets/tabbar/1.png'),
                    iconA: require('@/assets/tabbar/1-1.png'),
                    url: '/home'
                },
                {
                    title: this.$i18n.t('tabbar.市场'),
                    icon: require('@/assets/tabbar/2.png'),
                    iconA: require('@/assets/tabbar/2-2.png'),
                    url: '/market'
                },

                {
                    title: this.$i18n.t('tabbar.团队'),
                    icon: require('@/assets/tabbar/4.png'),
                    iconA: require('@/assets/tabbar/4-4.png'),
                    url: '/team'
                },
                {
                    title: this.$i18n.t('tabbar.我的'),
                    icon: require('@/assets/tabbar/5.png'),
                    iconA: require('@/assets/tabbar/5-5.png'),
                    url: '/my'
                },
                // {
                //   title: this.$i18n.t('客服'),
                //   icon: require('@/assets/tabbar/3.png'),
                //   iconA: require('@/assets/tabbar/3-3.png'),
                //   url: '/my'
                // }
            ]
        }
    },

    methods: {
        changlang() {
            this.$router.push('/lang')
        },
        captchaEvent() {
            this.textContent = '12miao'
        },
        getBack() {
            this.$router.back()
        },
        subpasswd() {
            if (this.password != this.password2) {
                Toast.fail(this.$t('两次密码不一致'));
                return;
            }
            if (!this.captcha) {
                Toast.fail(this.$t('请输入验证码'));
                return;
            }
            if (this.checkEmail(this.username)) {
                this.key = 'username'
            }

            if (this.checkMobile(this.username)) {
                this.key = 'mobile'
            }
            apiForget({
                where_key: this.key,
                passwd: this.password2,
                code: this.captcha,
                username: this.username,
                type: "reset"
            }).then((res) => {
                if (res.status != 0) {
                    Toast.success(this.$t(res.info));
                    window.history.back();
                } else {
                    Toast.fail(this.$t(res.info));

                }
            });
        },
        getCode2() {
            const TIME_COUNT = 60;
            if (!this.timer) {
                this.count = TIME_COUNT;
                this.show = false;
                this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= TIME_COUNT) {
                        this.count--;
                    } else {
                        this.show = true;
                        clearInterval(this.timer);
                        this.timer = null;
                    }
                }, 1000)
            }
        },
        checkMobile(str) {
            // let re = /^1\d{10}$/
            let re = /^\d\d{10}$/;
            return re.test(str);
        },
        checkEmail(str) {
            let re = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/
            return re.test(str)
        },
        getcode() {
            if (!this.username) {
                Toast.fail(this.$t('手机号码') + ',' + this.$t("邮箱不能为空"));
                return;
            }

            if (this.checkEmail(this.username)) {
                this.codeDisabled = true;
                this.timer1 = setInterval(() => {
                    if (this.codeTime > 0 && this.codeTime <= 60) {
                        this.codeTime--;
                    } else {
                        this.codeDisabled = false
                        this.codeTime = 60
                        clearInterval(this.timer1);
                        this.timer1 = null;
                    }
                },1000);
                this.key = 'username'
                apiRegssByMail({
                    username: this.username,
                    type: 'findpwd',
                }).then((res) => {
                    if (res.status != 0) {
                        Toast.success(this.$t(res.info));
                    } else {
                        Toast.fail(this.$t(res.info));
                    }
                });
                return;
            }

            if (this.checkMobile(this.username)) {
                this.codeDisabled = true;
                this.timer1 = setInterval(() => {
                    if (this.codeTime > 0 && this.codeTime <= 60) {
                        this.codeTime--;
                    } else {
                        this.codeDisabled = false
                        this.codeTime = 60
                        clearInterval(this.timer1);
                        this.timer1 = null;
                    }
                },1000);
                this.key = 'mobile'
                apiSmsCode({
                    username: this.username,
                    type: 'findpwd'
                }).then((res) => {
                    if (res.status != 0) {
                        Toast.success(this.$t(res.info));
                    } else {
                        Toast.fail(this.$t(res.info));
                    }
                });
                return;
            }
            Toast.fail(this.$t('格式错误'));


            // apiRegss({
            //     username: this.username,
            //
            //     optionval: this.optionval,
            //
            // }).then((res) => {
            //     console.log(res);
            //     if (res.status) {
            //
            //         Toast.success(this.$t(res.info));
            //
            //         this.getCode2();
            //     } else {
            //         Toast.fail(this.$t(res.info));
            //     }
            // });
        },
    }
}
</script>

<style lang="scss" scoped>
.page {
  .header {
    padding: 0 10px;
    height: 56px;
    line-height: 56px;
    display: flex;
    justify-content: space-between;

    .close {
      width: 28px;
      height: 28px;
    }

    .img {
      width: 40px;
    }

    .logg {
      font-size: 40px;
      font-family: PingFang SC-中粗体, PingFang SC;
      font-weight: normal;
      color: #ffffff;
    }
  }

  .van-field {
    background-color: #2B2B2B !important;
  }

  .van-field__control {
    background-color: #2B2B2B !important;
  }

  .van-dropdown-menu .van-dropdown-menu__bar {
    background-color: #2B2B2B !important;
  }

  .btn {
    box-shadow: 0 0.13333rem 0.26667rem #0d9135;
    font-size: 0.4rem;
    height: 1.37333rem;
    background-color: #0d9135;
    color: #fff;
  }

  .phone {
    display: flex;
  }

  .captcha {
    height: 100%;
    padding: 10px;
    margin-bottom: 30px;
    display: flex;

    border-radius: 20px;

    .textBtn {
      width: 400px !important;
    }

    ::v-deep .van-button--plain.van-button--info {
      color: #0d9135;
      padding-top: 20px;
      padding-bottom: 20px;
      box-sizing: border-box;
    }
  }

  .content {
    margin-top: 90px;
    padding: 0 36px;

    .prefix {
      width: 180px;
      margin-right: 30px;

      ::v-deep input.van-field__control {
        color: #0d9135;
      }
    }
  }

  ::v-deep .van-button--plain {
    background-color: #2B2B2B;
  }

  ::v-deep.van-button--info {
    border: none;
  }
}
</style>
